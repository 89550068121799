import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import PhotoGrid from "../components/PhotoGrid"
import Img from "gatsby-image"
import { trackCustomEvent } from 'gatsby-plugin-google-analytics'

const IndexPage = ({data}) => {

  const locationsByCity = data.allLocationsJson.group;

  const totalCount = data.locationCount.totalCount;

  const featuredCities = [
    'canada/ontario/toronto',
    'united-states/new-york/new-york-city',
    'canada/quebec/montreal',
    'canada/ontario/ottawa',
    'canada/british-columbia/vancouver',
    'united-kingdom/england/london',
    'united-states/illinois/chicago',
  ]

  let featuredCityLocations = []; 
  featuredCities.forEach((city) => {
    const group = locationsByCity.filter((item) => {
      return item.fieldValue === city
    });
    if (group[0] && group[0].nodes) {
      featuredCityLocations.push(group[0]);
    }
  })

  const otherCityLocations = locationsByCity.filter((group) => {
    return !featuredCities.includes(group.fieldValue);
  })

  const otherCityLocationsSorted = otherCityLocations.sort((a, b) => {
    return a.nodes[0].city.localeCompare(b.nodes[0].city);
  });

  return (
    <Layout>
      <SEO description="An archive of signs on storefronts, parks, and public spaces from cities around the world during the coronavirus pandemic" title="" image={data.placeholderImage.childImageSharp.fluid.src} />

      <div className="intro">
        {/* <div className="intro-image">
          <Img style={{ marginBottom: `1rem`, borderRadius: `5px`, width: `300px`, float: `right`, marginLeft: `2rem` }} fluid={data.placeholderImage.childImageSharp.fluid} />
        </div> */}
        <p>
          During the coronavirus pandemic, daily life has come to a sudden standstill and businesses have had to respond. Signs on storefronts announce operational changes but these messages are also brimming over with solidarity, shared responsibility, and cautious optimism. This project attempts to document the temporary signs that have gone up across our communities. 
        </p>
        <p>
          This archive features {totalCount} sign photos from <Link onClick={e => {trackCustomEvent({category: "navigation", action: "cities", label: "/"})}}to="cities">{locationsByCity.length} cities</Link> across the world. You can <Link onClick={e => {trackCustomEvent({category: "navigation", action: "about", label: "/"})}} to="about">learn more about</Link> the project or <Link onClick={e => {trackCustomEvent({category: "navigation", action: "submit", label: "/"})}} to="submit">submit a photo</Link> of your own.
          We're also posting photos <a href="https://instagram.com/duetocovid19" onClick={e => {trackCustomEvent({category: "external-link", action: "instagram"})}}>Instagram</a> and <a href="https://twitter.com/duetocovid19" onClick={e => {trackCustomEvent({category: "external-link", action: "twitter"})}}>Twitter</a>.
        </p>
      </div>

      <section>
        <h2>Featured signs</h2>
        <PhotoGrid numPhotos={8} showArrow={true} title={`Featured signs`} photos={data.featuredImages.nodes}></PhotoGrid>
      </section>

      {featuredCityLocations.map((item, i) => (
        <section key={i}>
          <h2>
            <Link to={item.nodes[0].fields.cityFullPath}>
              {item.nodes[0].city}
            </Link>
          </h2>
          <PhotoGrid key={i} totalCount={item.totalCount} numPhotos={8} showArrow={true} title={item.nodes[0].city} photos={item.nodes}></PhotoGrid>
        </section>
      ))}

      <section>
        <h2><Link onClick={e => { trackCustomEvent({ category: "navigation", action: "all-cities", label: "/" }) }} to="cities">Other cities &rarr;</Link></h2>
        {otherCityLocationsSorted.map((item, i) => (
          <Link key={`other-${i}`} className='more-link city-name' to={item.nodes[0].fields.cityFullPath}>{item.nodes[0].city} <span className="count">{item.totalCount}</span></Link>
        ))}
      </section>

      <section>
        <h2>Don't see your city?</h2>
        <Link className='more-link' to="/submit">Submit your images &rarr;</Link>
      </section>
      
    </Layout>
  )
}



export const query = graphql`

query AllLocationsGroupedByCity {

  locationCount: allLocationsJson {
    totalCount
  }

  placeholderImage: file(relativePath: { eq: "banner-narrow.jpg" }) {
    childImageSharp {
      fluid(maxWidth: 500) {
        ...GatsbyImageSharpFluid
      }
    }
  }
  featuredImages: allLocationsJson(sort: {order: DESC, fields: images___rating}, filter: {images: {elemMatch: {rating: {gt: 3}}}}) {
    nodes {
      id
      fields {
        path
        cityFullPath
      }
      city
      slug
      images {
        credit
        date(formatString: "ll")
        src {
          childImageSharp {
            fluid(maxWidth: 200) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }

  allLocationsJson(sort: {fields: images___date, order: DESC}) {
    group(field: fields___cityFullPath) {
      fieldValue
      totalCount
      nodes {
        id
        fields {
          path
          cityFullPath
        }
        city
        country
        slug
        images {
          credit
          title
          date(formatString: "ll")
          src {
            childImageSharp {
              fluid(maxWidth: 200) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
}
`

export default IndexPage
